<template>
  <v-container>
    <v-row class="inkoop">
      <v-col>
        <div class="container">
          <div class="left">
            <h3>Info</h3>
            Postzegels.com - Postzegel Partijen Centrale<br />
            koningin Julianalaan 214<br />
            2274 JP, Voorburg
          </div>

          <div class="right">
            Telefoon : +31 (0)70 3625263<br />
            Email : admin@postzegels.com<br />
            ING: 7331882 ten name van P.P.C. te Voorburg<br />
            IBAN: NL42INGB0007331882<br />
            BTW-NR: NL002064040B86<br />
            BIC : INGBNL2A
          </div>
        </div>
        <h3>Het team</h3>
        <div class="team-container">
          <div class="team-item">
            <img
              src="@/assets/inkoop/Ruud.jpg"
              width="200"
              height="200"
              class="team-image"
            />
            <div class="team-description">
              Ruud Stibbe<br />
              Eigenaar
            </div>
          </div>
          <!-- 
  <div class="team-item">
    <img src="@/assets/inkoop/Jorden.jpg" width="200" height="200" class="team-image">
    <div class="team-description">
      Jorden van der Ven<br>
      Inkoper
    </div>
  </div>-->

          <!-- <div class="team-item">
    <img src="@/assets/inkoop/Yadira.jpg" width="200" height="200" class="team-image">
    <div class="team-description">
      Yadira Pretel<br>
      Algemeen medewerker
    </div>
  </div>-->
        </div>
      </v-col>
      <v-col cols="4">
        <!--<img src="@/assets/inkoop/nieuwsflash.jpg" /> -->
        <img src="@/assets/inkoop/garantie.jpg" />
        <!-- img src="@/assets/inkoop/verzending.jpg" />-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.team-container {
  display: flex;
  flex-wrap: wrap;
}

.team-item {
  flex-basis: 200px;
  margin-right: 20px; /* Optioneel: voeg wat ruimte toe tussen de afbeeldingen */
}

.team-image {
  width: 200px;
  height: 200px;
}

.team-description {
  margin-top: 10px; /* Optioneel: voeg wat ruimte toe tussen de afbeelding en de tekst */
}

.team {
  margin: 4px;
}
.container {
  display: flex;
}

.left {
  margin-right: 20px; /* Optioneel: voeg wat ruimte toe tussen de elementen */
}

.right {
  margin-left: 20px;
}
.inkoop {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.inkoop h3 {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: medium;
}
</style>
